// React imports
import React, { Component } from 'react';
import Select from 'react-select';

class LangSelect extends Component {
  constructor(props) {
    super(props);

    this.state = {
      language: ''
    };
  };

  render() {
    return (
      <Select 
          className={this.props.className}
          name="language"
          value={this.state.language}
          placeholder={this.props.locale.dropdownPlaceholderLang}
          noResultsText={this.props.locale.dropdownNoResults}
          options={this.props.languages}
          onChange={(function(value) {
            let language = {...this.state.language};
            
            language = value;
            this.props.locale.setLanguage(language.code);

            this.setState({
              language: language
            });
          }).bind(this)}
      />
    );
  };
}

export default LangSelect;