// React imports
import React, { Component } from "react";

class Footer extends Component {
  /*constructor(props) {
    super(props);
  };*/

  render() {
    return (
      <footer className="footer">
        <div className="container">
          <div className="content has-text-centered">
            <p className="justify-text">{this.props.locale.footerContent}</p>
            <figure className="image is-128x128 image-inline logo-footer1">
              <img src={this.props.logo} alt="" />
            </figure>
            <figure className="image is-128x128 image-inline logo-footer2">
              <img src={this.props.logoTapio} alt="" />
            </figure>
            <figure className="image is-128x128 image-inline logo-footer3">
              <img src={this.props.logoBitcomp} alt="" />
            </figure>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
