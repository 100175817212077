// React imports
import React, { Component } from "react";
import LocalizedStrings from "react-localization";

// App imports
import NavBar from "./js_components/navbar";
import Hero from "./js_components/hero";
import Calculator from "./js_components/calculator";
import Footer from "./js_components/footer";

// Data imports
import AppLogoFi1 from "./data/mk_logo_vaaka_FI.png";
import AppLogoSv1 from "./data/skogscentralen_logo.png";
//import AppLogo2 from './data/logo-vaaka.png';
import TapioLogo from "./data/logo-tapio.png";
import BitcompLogo from "./data/logo-bitcomp.png";
import JSONMunicipalities2017 from "./data/municipalities_2017.json";
import JSONMunicipalities2018 from "./data/municipalities_2018.json";
import JSONMunicipalities2019 from "./data/municipalities_2019.json";
import JSONMunicipalities2020 from "./data/municipalities_2020.json";
import JSONMunicipalities2021 from "./data/municipalities_2021.json";
import JSONMunicipalities2022 from "./data/municipalities_2022.json";
import JSONMunicipalities2023 from "./data/municipalities_2023.json";
import JSONTaxClasses from "./data/taxclasses.json";
import JSONLanguages from "./data/languages.json";

// Style imports
import "bulma/css/bulma.css";
import "font-awesome/css/font-awesome.css";
import "react-select/dist/react-select.css";
import "./css/App.css";

// App localization
let locale = new LocalizedStrings({
  fi: {
    year: "Metsän lahjoitusvuosi",
    logoAlt: "Metsäkeskus",
    heroTitle: "METSÄLAHJAVÄHENNYSLASKURI",
    heroSubtitle:
      "Metsäomaisuuden luovutuksiin liittyvän metsälahjavähennyksen ja metsävähennyksen arviointiin.",
    heroText1:
      "Metsälahjavähennyslaskuri on tehty metsäomaisuuden luovutuksiin liittyvän metsälahjavähennyksen arviointiin, mutta se huomioi myös metsävähennyksen. Laskuri ei huomioi metsäkiinteistöihin liittyviä muita mahdollisia arvoja kuten rakennuksia. ",
    heroText2:
      "Laskenta aloitetaan valitsemalla lahjoitusvuosi. On huomioitava, että laskuri käyttää verohallinnon vahvistamia tuottoarvoja, euroa/ha vuosille 2017–2023, joiden mukaan metsän lahjoitusvuoden voi valita. Vuoden 2024 tuottoarvon mukainen vuosivalinta lisätään laskuriin, kun verohallinto on vahvistanut metsän laskennallisen tuoton vuodelle 2024. Tulevia luovutuksia suunniteltaessa voi käyttää viimeisintä vuotta suuntaa antavassa laskelmassa. Lisätietoja metsälahjavähennyksestä löytyy Suomen metsäkeskuksen sivuilta ",
    footerContent:
      "Laskurin toteuttajat eivät anna käyttäjälle takuuta laskurissa olevan tiedon ajantasaisuudesta, virheettömyydestä tai käyttökelpoisuudesta tiettyyn nimenomaiseen tarkoitukseen. Laskurin toteuttajat eivät myöskään millään tavoin takaa laskurin käyttäjälle tietyn tai halutun lopputuloksen saavuttamista kaikissa olosuhteissa. Laskurin toteuttajat eivät vastaa suhteessa käyttäjään kulusta, menetyksestä tai vahingosta, joka mahdollisesti aiheutuu laskurin käyttämisestä.",
    footerCopyright: "© Bitcomp Oy 2020",
    calcEstateGridTitle: "Lahjoitettavat metsäkiinteistöt",
    calcEstateGridColDesc: "Kuvaus",
    calcEstateGridColMun: "Kunta",
    calcEstateGridColProf: "Metsän tuotto, €/ha",
    calcEstateGridColArea: "Metsämaan pinta-ala, ha",
    calcEstateGridColFunc: "Toiminnot",
    calcEstateGridBtnAddRow: "Lisää rivi",
    calcEstateGridBtnRemRow: "Poista",
    calcEstateGridColSum: "Lahjan metsämaa yht., ha: ",
    calcCalcGridTitle: "Lähtötiedot",
    calcCalcTaxClass: "Lahjaveroluokka: ",
    calcCalcCurrentPrice: "Käypä arvo, €: ",
    calcCalcGiftTax: "Lahjavero: ",
    calcCalcforestryGiftCut: "Metsälahjavähennyspohja: ",
    calcCalcInvestmentIncome: "Pääomatulotarve: ",
    calcCalcInvestmentIncomeTax: "Pääomatulovero %: ",
    calcCalcNetProfit: "Nettohyöty: ",
    calcCalcAmountOfGifts: "Lahjojen kpl. määrä: ",
    calcCalcTradePercentage: "Kaupan osuus %: ",
    calcCalcTradePrice: "Kauppahinta: ",
    calcCalcGiftTaxAmount: "Varainsiirtovero: ",
    calcCalcTradeArea: "Kaupan pinta-ala: ",
    calcCalcTradeOtherCosts: "Kaupan muut kustannukset, €: ",
    calcCalcGiftForestryCut: "Siirtyvä metsävähennyspohja, €: ",
    calcCalcLeftOverForestryCut: "Aiempi metsävähennyspohja, €: ",
    calcCalcOverallForestryCut: "Metsävähennyspohja yht.: ",
    calcCalcTradeForestryCut: "Kaupassa syntyvä metsävähennyspohja: ",
    calcCalcForestryCutNetProfit:
      "Kaupassa syntyvän metsävähennyksen nettohyöty: ",
    calcCalcFInvestmentIncomeTUFC:
      "Metsätalouden pääomatulot vastikkeellisen osan metsävähennyksen hyödyntämiseksi: ",
    calcCalcGridBtnClear: "Tyhjennä",
    calcCalcGridBtnExecute: "Laske",
    calcResultsGridTitle: "Laskennan tulokset",
    calcResultsGridBtnPdf: "Tulokset PDF:nä",
    dropdownPlaceholder: "Valitse...",
    dropdownPlaceholderLang: "Valitse kieli...",
    dropdownLangLabel: "Vaihda kieli / Växla språk",
    descChooseLang: "Sovelluksen kielen valinta: ",
    dropdownNoResults: "Ei tuloksia",
    hectare: "ha",
    ttYearDescription:
      "Metsän lahjoitusvuosi selviää lahjakirjan päiväyksestä. Eri vuosina ja eri henkilöiltä lahjana saaduille metsille on metsälahjavähennyspohja laskettava erikseen.",
    ttEstateDescription:
      "Vapaaehtoinen käyttäjän antama lisätieto. Esim. kiinteistön nimi tai tunnus.",
    ttEstateMunicipality:
      "Kunta, jonka alueella lahjoitettu metsäkiinteistö tai kiinteistöt sijaitsee. Jos lahjoituksen jälkeen on tapahtunut kuntafuusio, valitaan kunnaksi metsän sijaintikunta lahjoitusvuonna.",
    ttEstateProfitNumDefault:
      "Kuntakohtainen verottajan vahvistama tuotto, euroa/ha. On huomattava, että tuottoarvo muuttuu vuosittain verottajan päätöksen mukaan.",
    ttEstateProfitNum:
      "Kuntakohtainen, verohallinnon vahvistama metsän tuotto, euroa/ha metsän lahjoitusvuonna. On huomattava, että laskennallinen tuotto, euroa/ha muuttuu vuosittain verohallinnon päätöksellä. Tämän vuoksi laskurissa on aina käytettävä metsän todellista lahjoitusvuotta (vuosivalinta alussa). Vuoden 2024 tuottoluvut lisätään laskuriin marraskuussa 2024.",
    ttEstateArea:
      "Lahjoitettavan metsäkiinteistön tai kiinteistöjen metsämaan pinta-ala kunnassa, ha. Laskurissa on käytettävä verohallinnon vahvistamaa metsämaan pinta-alaa, joka on merkitty esitäytettyyn veroilmoitukseen. Käytettävä pinta-ala saattaa poiketa esim. kiinteistön pinta-alasta tai metsäsuunnitelman mukaisesta pinta-alasta.",
    ttEstateAreaSum:
      "Kaikkien lahjoitettavien metsäkiinteistöjen yhteen lahjaan kuuluva metsämaan pinta-ala yhteensä, ha.",
    ttCalcTaxClass:
      "I-veroluokkaan kuuluvat lahjanantajan aviopuoliso, lahjanantajaan suoraan ylenevässä tai alenevassa polvessa oleva sukulainen ja aviopuolison suoraan alenevassa polvessa oleva sukulainen. Aviopuolisona pidetään myös lahjanantajan kanssa avioliitonomaisissa olosuhteissa elävää henkilöä, joka on aikaisemmin ollut avioliitossa lahjanantajan kanssa tai jolla on tai on ollut yhteinen lapsi lahjanantajan kanssa. Ottolapset ja -vanhemmat ovat verotuksellisesti samassa asemassa biologisten sukulaisten kanssa. II-veroluokkaan kuuluvat muut sukulaiset ja vieraat.",
    ttCalcCurrentPrice:
      "Lahjan kohteena olevan metsäomaisuuden (metsäkiinteistön tai -kiinteistöjen) käypä arvo yhteensä euroa. Jos lahjavero on jo maksettu, käytetään lahjaveron perusteena ollutta arvoa. Omaisuuden luovutuksia suunniteltaessa käypä arvo saadaan esim. tila-arvioista. Arvoltaan merkittävien metsätilojen ollessa kyseessä, voi verohallinnolta pyytää ennakkopäätöksen käyvästä arvosta.",
    ttCalcInvestmentIncomeTax:
      "Lahjan saajan pääomatulojen veroprosentti, joka riippuu lahjan saajan kaikista pääomatuloista ja on vähintään 30 %, mutta jää alle 34 %.",
    ttCalcAmountOfGifts:
      "Monenako lahjana sama metsäomaisuus luovutetaan identtisinä luovutuksina. Arvo 1 tarkoittaa, että koko metsäomaisuus luovutetaan yhtenä lahjana, ja 2 että se jaetaan yhtä suurin osuuksin kahtena lahjana jne.",
    ttCalcTradePercentage:
      "Vastikkeellisen luovutuksen osuus metsäomaisuudesta %. Käytetään, jos luovutuksessa on vastikkeellista kauppaa. Jos vastikkeellisen luovutuksen osuus on yli 75 % käyvästä arvosta, on lahjaveron määrä ja metsälahjavähennys aina 0 euroa.",
    ttCalcTradeOtherCosts:
      "Vastikkeellisen luovutuksen muut kustannukset, jotka lisäävät metsävähennyksen määrää (esim. lainhuudatuskulut ja kaupanvahvistajan palkkio, asiakirjakulut yms).",
    ttCalcGiftForestryCut:
      "Lahjan mukana edelliseltä omistajalta siirtyvä metsävähennyspohja.",
    ttCalcLeftOverForestryCut:
      "Luovutuksen saajalle aikaisemmin muodostuneet käyttämättömät metsävähennyspohjat. Jos lahjan saajina on kaksi tai useampia henkilöitä, saannosta muodostuu verotusyhtymä. Tällöin saajien aikaisemmat henkilökohtaiset metsävähennyspohjat eivät siirry verotusyhtymälle.",
    ttRsultGiftTax:
      'Metsään kohdistuvan lahjaveron määrä. Kun vastikkeellisen luovutuksen osuus on yli 75 % on lahjavero aina 0 euroa. Mikäli lahjaveron määrä on jo tiedossa, merkitse rasti "Muokkaa"-ruutuun ja kirjoita lahjaveron määrä kenttään.',
    ttRsultForestryGiftCut:
      "Metsälahjavähennyspohja (vähennyksen käytön vähimmäismäärä 1500 euroa/vuosi).",
    ttRsultInvestmentIncome:
      "Metsälahjavähennyspohjan maksimaaliseksi käyttämiseksi tarvittava metsätalouden puhdas pääomatulo 15 v aikana yhdellä lahjan saajalla.",
    ttRsultNetProfit:
      "Metsälahjavähennyksen maksiminettohyöty euroa. Laskurin laskelmassa ei ole huomioitu mahdollista yrittäjävähennyksen vaikutusta.",
    ttRsultTradePrice: "Maksettavan vastikkeen määrä euroa.",
    ttRsultGiftTaxAmount:
      "Vastikkeellisen luovutuksen osuudesta maksettava varainsiirtovero euroa.",
    ttRsultTradeArea: "Vastikkeellisen luovutuksen osuuden metsäpinta-ala.",
    ttRsultOverallForestryCut:
      "Luovutuksen saajan käyttämätön metsävähennyspohja yhteensä. Kauppahinta lisättynä varainsiirtoverolla ja muilla kustannuksilla ja kerrottuna luvulla 0,6 sekä lisättynä lahjassa ja lahjanluontoisessa kaupassa siirtyvillä metsävähennyksillä sekä luovutuksensaajan aiemmalla käyttämättömällä metsävähennyspohjalla.",
    ttRsultTradeForestryCut:
      "Vastikkeellisen luovutuksen osuudesta muodostuva metsävähennyspohja.",
    ttRsultGiftForestryCutNetProfit:
      "Vastikkeellisen luovutuksen osuudesta muodostuvan metsävähennyksen nettohyöty.",
    ttRsultFInvestmentIncomeToUtilizeForestryCut:
      "Metsätalouden pääomatulojen määrä, joka tarvitaan, jotta vastikkeellisen luovutuksen osuuden metsävähennyspohja saadaan täysin hyödynnettyä.",
    pdfTitle: "Metsälahjavähennys - Laskennan tulokset",
    pdfSubtitle1: "Tulokset on laskettu vuoden ",
    pdfSubtitle2: " tuottoluvuilla.",
    pdfAuthor: "Bitcomp Oy",
    pdfCreator: "Metsälahjavähennyslaskuri",
    pdfKeywords: "pdf, melvl, bitcomp, tapio",
    pdfFileName: "laskennan-tulokset",
    requiredField: "Arvo vaaditaan...",
    municipalityPlaceholder: "Valitse lahjoitusvuosi...",
    requiredTooltip: "Pakollinen kenttä laskennan kannalta.",
    validFailedNotification: "Virhe! Pakollinen kenttä.",
    editCheckboxLabel: "Muokkaa",
  },
  sv: {
    year: "Gåvoår för skogen",
    logoAlt: "Skogscentralen",
    heroTitle: "KALKYLATOR FÖR SKOGSGÅVOAVDRAG",
    heroSubtitle:
      "För uppskattning av skogsgåvoavdrag och skogsavdrag i samband med överlåtelse av skogsegendom.",
    heroText1:
      "Kalkylatorn för skogsgåvoavdraget är gjord för att beräkna skogsgåvoavdraget vid överlåtelse av skogsegendom, men den beaktar även skogsavdraget. Kalkylatorn beaktar inte eventuella andra värden som hör till skogsfastigheterna, exempelvis byggnader.",
    heroText2:
      "Beräkningen inleds med att välja året när gåvan erhållits. Man bör observera att kalkylatorn använder skatteförvaltningens fastställda avkastningsvärden, euro/ha för åren 2017 – 2023, enligt vilka man kan välja  år när skogen överlåtits. Avkastningsvärdena för år 2024 läggs till i kalkylatorn när skatteförvaltningen har fastställt skogens beräknade avkastning för år 2024. Vid planeringen av kommande överlåtelser kan man använda det senaste årets värden till en riktgivande beräkning. Tilläggsinformation om skogsgåvoavdraget finns på Finlands skogscentrals sidor ",
    footerContent:
      "Utgivarna av kalkylatorn ger inga garantier åt användarna beträffande informationens aktualitet, felfrihet eller användbarhet för vissa specifika ändamål. Utgivarna ger heller inga garantier för att användaren uppnår ett visst  eller önskat slutresultat i alla situationer. Utgivarna ansvarar inte heller gentemot användaren för kostnader, förluster eller skada som eventuellt orsakas av användningen av kalkylatorn.",
    footerCopyright: "© Bitcomp Oy 2020",
    calcEstateGridTitle: "Skogsfastigheter som ges i gåva",
    calcEstateGridColDesc: "Beskrivning",
    calcEstateGridColMun: "Kommun",
    calcEstateGridColProf: "Skogens avkastning, e/ha",
    calcEstateGridColArea: "Skogsmarkens areal, ha",
    calcEstateGridColFunc: "Toiminnot",
    calcEstateGridBtnAddRow: "Lägg till rad",
    calcEstateGridBtnRemRow: "Avlägsna",
    calcEstateGridColSum: "Gåvans skogsmark tot., ha",
    calcCalcGridTitle: "Grunduppgifter",
    calcCalcTaxClass: "Gåvoskatteklass: ",
    calcCalcCurrentPrice: "Gängse värde, €: ",
    calcCalcGiftTax: "Gåvoskatt: ",
    calcCalcforestryGiftCut: "Skogsgåvoavdragsgrund: ",
    calcCalcInvestmentIncome: "Kapitalinkomstbehov: ",
    calcCalcInvestmentIncomeTax: "Kapitalinkomstskatt %: ",
    calcCalcNetProfit: "Nettonytta: ",
    calcCalcAmountOfGifts: "Gåvornas antal, st: ",
    calcCalcTradePercentage: "Köpets andel %: ",
    calcCalcTradePrice: "Köpesumma: ",
    calcCalcGiftTaxAmount: "Överlåtelseskatt: ",
    calcCalcTradeArea: "Köpets areal: ",
    calcCalcTradeOtherCosts: "Köpets övriga omkostnader, €: ",
    calcCalcGiftForestryCut: "Skogsavdragsgrund som medföljer, €: ",
    calcCalcLeftOverForestryCut: "Skogsavdragsgrund från tidigare, €: ",
    calcCalcOverallForestryCut: "Skogsavdragsgrund sa: ",
    calcCalcTradeForestryCut: "Skogsavdragsgrund som uppstår vid köpet: ",
    calcCalcForestryCutNetProfit: "Nettonytta av skogsavdrag från köpet: ",
    calcCalcFInvestmentIncomeTUFC:
      "Skogsbrukets kapitalinkomst för att utnyttja skogsavdragets andel av köpesumman: ",
    calcCalcGridBtnClear: "Töm",
    calcCalcGridBtnExecute: "Räkna",
    calcResultsGridTitle: "Beräkningens resultat",
    calcResultsGridBtnPdf: "Resultaten i PDF-form",
    dropdownPlaceholder: "Välj...",
    dropdownPlaceholderLang: "Välj språk...",
    dropdownLangLabel: "Vaihda kieli / Växla språk",
    descChooseLang: "Sovelluksen kielen valinta: ",
    dropdownNoResults: "Inga resultat",
    hectare: "ha",
    ttYearDescription:
      "Gåvoår för skogen framgår av dateringen på gåvobrevet. För skogar som erhållits i gåva av olika personer och olika år skall skogsgåvoavdraget beräknas separat.",
    ttEstateDescription:
      "Tilläggsinformation som användaren kan tillfoga på eget initiativ. Exempelvis lägenhetens namn eller registernummer.",
    ttEstateMunicipality:
      "Kommun, inom vars område den överlåtna skogsfastigheten eller fastigheterna befinner sig. Om det efter överlåtelsen har skett kommunfusion, väljs den kommun inom vilken skogen befann sig vid överlåtelseåret.",
    ttEstateProfitNumDefault:
      "Skattestyrelsens per fastställda kommunvisa avkastning, euro/ha. Observera att värdena ändras årligen på basen av beskattarens beslut.",
    ttEstateProfitNum:
      "Av skatteförvaltningen kommunvis fastställd avkastning för skog, euro/ha vid skogens överlåtelseår. Observera att den beräknade avkastningen, euro/ha, ändrar årligen genom skatteförvaltningens beslut. Därför skall i kalkylatorn alltid användas det verkliga överlåtelseåret för skogen (valet av år i början). Avkastningsvärdena för år 2024 läggs till kalkylatorn i november 2024.",
    ttEstateArea:
      "Den överlåtna skogsfastighetens eller fastigheternas skogsareal i kommunen, ha. I kalkylatorn skall användas den av skatteförvaltningen fastställda arealen för skogsmark som finns angiven på den förhandsifyllda skattedeklarationen. Arealen som skall användas kan avvika t. ex från fastighetens areal eller från arealen enligt skogsbruksplanen.",
    ttEstateAreaSum:
      "Den totala arealen för alla de via samma gåva hörande överlåtna skogsfastigheternas skogsmarksareal, ha.",
    ttCalcTaxClass:
      "Till skatteklass I hör gåvogivarens make, gåvogivarens släkting i rakt uppstigande eller nedstigande led och makes släkting i rakt nedåtstigande led. Som äkta make betraktas även en person som lever i äktenskapsliknande förhållanden med gåvogivaren, som tidigare varit gift med gåvogivaren eller har eller har haft gemensamt barn med gåvogivaren. Adoptivbarn och -föräldrar jämställs skattemässigt med de biologiska släktingarna. Till skatteklass II hör övriga släktingar och utomstående.",
    ttCalcCurrentPrice:
      "Sammanlagt gängse värde euro på skogsegendomen (skogslägenheten eller lägenheterna) som ges i gåva. Om gåvoskatten redan är betald, används värdet som fastställts vid gåvobeskattningen. Vid planeringen av egendomsöverlåtelser fås gängse värde exempelvis från lägenhetsvärderingar. Är det fråga om skogsfastigheter med betydande värden, kan man begära förhandsutlåtande av skatteförvaltningen ifråga om lägenheternas gängse värde.",
    ttCalcInvestmentIncomeTax:
      "Gåvomottagarens skatteprocent för kapitalinkomster. Den är beroende av gåvomottagarens samtliga kapitalinkomster, minst 30 % men stannar under 34 %.",
    ttCalcAmountOfGifts:
      "Anger i hur många gåvor skogsegendomen överlåts som identiska överlåtelser. Värdet 1 anger att hela skogsegendomen överlåts som en gåva, värdet 2 att den delas i två lika stora delar i form av två gåvor osv.",
    ttCalcTradePercentage:
      "Vederlagets andel i % vid överlåtelsen av skogsegendomen. Används ifall vederlag ingår som del av överlåtelsen. Om vederlagets andel av överlåtelsen överstiger 75 %, är gåvoskatten och avdragsgrunden för skogsavdraget alltid 0 euro.",
    ttCalcTradeOtherCosts:
      "Övriga omkostnader som hänför sig till överlåtelsen mot vederlag, vilka ökar storleken på skogsavdraget (t.ex lagfartskostnader och köpvittnesarvoden, kostnader för uppgörande av handlingar mm).",
    ttCalcGiftForestryCut:
      "Skogsavdragsgrund som medföljer gåvan från den tidigare ägaren.",
    ttCalcLeftOverForestryCut:
      "Oanvänt skogsavdrag som uppstått hos gåvomottagaren sedan tidigare. Om gåvomottagare är två eller flera personer, uppstår en beskattningssammanslutning från fånget. Härvidlag överflyttas inte mottagarnas personliga skogsavdragsgrunder till beskattningssammanslutningen.",
    ttRsultGiftTax:
      "Gåvoskatt som hänför sig till skogsmarken. När köpesummans andel av överlåtelsen överstiger 75 %, är gåvoskatten alltid 0 euro. Om beloppet av gåvoskatten redan är känt, sätt kryss i rutan ”Redigera” och skriv beloppet av gåvoskatten i fältet.",
    ttRsultForestryGiftCut:
      "Skogsgåvans avdragsgrund (avdragets minimibelopp 1500 euro/år).",
    ttRsultInvestmentIncome:
      "Kapitalinkomstbehov	Skogsbrukets rena kapitalinkomst under 15 år som behövs för att utnyttja skogsgåvans avdragsgrund maximalt hos en gåvomottagare.",
    ttRsultNetProfit:
      "Maximal nettonytta i euro av skogsgåvoavdraget. I kalkylen har inte beaktats eventuell inverkan av företagaravdraget.",
    ttRsultTradePrice: "Storleken av vederlag som erläggs i euro.",
    ttRsultGiftTaxAmount:
      "Överlåtelseskatt i euro som erläggs för vederlagets andel av överlåtelsen.",
    ttRsultTradeArea: "Skogsarealens areal från överlåtelsen mot vederlag.",
    ttRsultOverallForestryCut:
      "Sammanlagd oanvänd skogsavdragsgrund hos gåvomottagaren. Köpesumman ökad med överlåtelseskatt och övriga omkostnader multiplicerad med talet 0,6 utökat med skogsavdrag som medföljer gåvan och det gåvoartade köpet och gåvomottagarens tidigare oanvända skogsavdragsgrund.",
    ttRsultTradeForestryCut:
      "Skogsavdragsgrund som uppstår från vederlagets andel av överlåtelsen.",
    ttRsultGiftForestryCutNetProfit:
      "Skogsavdragets nettonytta från vederlagets andel av överlåtelsen.",
    ttRsultFInvestmentIncomeToUtilizeForestryCut:
      "Storleken på skogsbrukets kapitalinkomst som behövs för att skogsavdragsgrunden från vederlagets andel av överlåtelsen kan utnyttjas fullt ut.",
    pdfTitle: "Skogsgåvokalkylatorn - Beräkningens resultat",
    pdfSubtitle1: "Resultaten är uträknade enligt ",
    pdfSubtitle2: " års avkastningsvärden.",
    pdfAuthor: "Bitcomp Oy",
    pdfCreator: "Metsälahjavähennyslaskuri",
    pdfKeywords: "pdf, melvl, bitcomp, tapio",
    pdfFileName: "beräkningens-resultat",
    municipalityPlaceholder: "Välj gåvoår...",
    requiredField: "Värde krävs...",
    requiredTooltip: "Obligatoriskt fält för beräkningen.",
    validFailedNotification: "Fel! Obligatoriskt fält.",
    editCheckboxLabel: "Redigera",
  },
});

// Component class
class App extends Component {
  render() {
    // App logo
    var AppLogo = null;

    // Do some i18n before rendering
    if (locale.getLanguage() === "fi") {
      JSONTaxClasses.forEach(function (taxClass) {
        taxClass.label = taxClass.label_fi;
        AppLogo = AppLogoFi1;
      });
    } else {
      JSONTaxClasses.forEach(function (taxClass) {
        taxClass.label = taxClass.label_sv;
        AppLogo = AppLogoSv1;
      });
    }

    return (
      <div id="App">
        <NavBar
          locale={locale}
          logo={AppLogo}
          logoAlt={locale.logoAlt}
          languages={JSONLanguages}
        />

        <Hero locale={locale} />

        <Calculator
          locale={locale}
          logo={AppLogo}
          logoTapio={TapioLogo}
          logoBitcomp={BitcompLogo}
          municipalities2017={JSONMunicipalities2017}
          municipalities2018={JSONMunicipalities2018}
          municipalities2019={JSONMunicipalities2019}
          municipalities2020={JSONMunicipalities2020}
          municipalities2021={JSONMunicipalities2021}
          municipalities2022={JSONMunicipalities2022}
          municipalities2023={JSONMunicipalities2023}
          taxClasses={JSONTaxClasses}
        />

        <Footer
          locale={locale}
          logo={AppLogo}
          logoTapio={TapioLogo}
          logoBitcomp={BitcompLogo}
        />
      </div>
    );
  }
}

export default App;
