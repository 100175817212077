// React imports
import React from 'react';
import ReactDOM from 'react-dom';

// App imports
import App from './App';

// Import index styles
import './css/index.css';

// Render the main app into the HTML DOM's div
function tick() {
  ReactDOM.render(
    <App />,
    document.getElementById('root')
  );
};

// Render loop, every second update view
setInterval(tick, 10);