// React imports
import React, { Component } from "react";

// App imports
import ToolTip from "./tooltip";

class Hero extends Component {
  /*constructor(props) {
    super(props);
  };*/

  render() {
    return (
      <section className="hero is-primary">
        <div className="hero-body">
          <div className="container">
            <div className="columns is-mobile">
              <div className="column">
                <h1 className="title title-hero">
                  {this.props.locale.heroTitle}
                </h1>
              </div>
            </div>
            <div className="columns is-mobile">
              <div className="column">
                <h2 className="subtitle justify-text">
                  {this.props.locale.heroSubtitle}
                </h2>
              </div>
            </div>
            <div className="columns is-mobile">
              <div className="column">
                <p className="justify-text">{this.props.locale.heroText1}</p>
              </div>
            </div>
            <div className="columns is-mobile">
              <div className="column">
                <p className="justify-text">
                  {this.props.locale.heroText2}
                  <u>
                    {this.props.locale.getLanguage() === "fi" ? (
                      <a
                        target="_blank"
                        href="https://www.metsakeskus.fi/metsalahjavahennyslaskuri"
                      >
                        linkki
                      </a>
                    ) : (
                      <a
                        target="_blank"
                        href="https://www.metsakeskus.fi/sv/aga-och-anvanda-skog/agarbyte/agarbyte-pa-skogsfastighet-genom-gava"
                      >
                        link
                      </a>
                    )}
                  </u>
                  <span>.</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Hero;
