// React imports
import React, { Component } from "react";

// App imports
import LangSelect from "./langselect";

class NavBar extends Component {
  /*constructor(props) {
    super(props);
  };*/

  render() {
    return (
      <nav className="nav has-shadow">
        <div className="container">
          <div className="nav-left">
            <a className="nav-item">
              <img src={this.props.logo} alt={this.props.logoAlt} />
            </a>
          </div>
          <div className="nav-right">
            <span className="nav-item nav-item-lang-select">
              <strong>{this.props.locale.dropdownLangLabel}:</strong>
            </span>
            <span className="nav-item nav-item-lang-select">
              <LangSelect
                className="lang-select"
                locale={this.props.locale}
                languages={this.props.languages}
              />
            </span>
          </div>
        </div>
      </nav>
    );
  }
}

export default NavBar;
