// React imports
import React, { Component } from 'react';

// App imports
// import Utils from './utils';

class ToolTip extends Component {
  constructor(props) {
    super(props);

    this.state = {
      icon: this.props.icon,
      text: this.props.text
    };
  };

  onFocus(event) {
    event.preventDefault();

    console.log('ToolTip::onFocus');
  };

  onBlur(event) {
    event.preventDefault();

    console.log('ToolTip::onBlur');
  };

  render() {
    return (
      <div className={(!this.props.customClass) ? 'tooltip' : this.props.customClass}>
        <span className="icon">
          <i className={this.props.icon}></i>
        </span>
        <span className="tooltiptext" onFocus={this.onFocus.bind(this)} onBlur={this.onBlur.bind(this)}>{this.props.text}</span>
      </div>
    );
  };
}

export default ToolTip;